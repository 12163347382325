import React from 'react'
import Layout from '../components/Layout'
import '../components/BlogBlock/styles.css'
import { Link, graphql } from 'gatsby'
import CloudinaryImage from '../components/CloudinaryImage'
import Seo from '../components/Seo'

import kebabcase from 'lodash.kebabcase'

const BlogsPage = data => (
  <Layout>
    <Seo title="Blog" />
    <div className="layout-narrow">
      <div className="blogBlock__wrapper">
        {data.data.allNodeArticle.edges.map(article => (
          <Link
            className="blogBlock"
            to={`blog/${article.node.drupal_internal__nid}/${kebabcase(
              article.node.title
            )}`}
          >
            <div className="blogBlock__content">
              <h2 className="blogBlock__heading">{article.node.title}</h2>
              <div className="blogBlock__subheading">
                {article.node.field_subheading}
              </div>
              <div className="blogBlock__date">{article.node.created}</div>
            </div>
            <CloudinaryImage
              className="blogBlock__image"
              height="300"
              width="652"
              src={article.node.relationships.field_image[0].uri.url}
            />
          </Link>
        ))}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allNodeArticle {
      edges {
        node {
          id
          drupal_internal__nid
          drupal_id
          title
          created(formatString: "MMM DD YYYY")
          field_subheading
          relationships {
            field_image {
              id
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default BlogsPage
